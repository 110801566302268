//Src/Comonent/Interest/socialMediaIcon.scss
@import "../../mixins";

.SocialMediaIcon {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #ffd13bee;
  border-top-left-radius: 45px;
  margin-left: 10px;
  margin-top: 10px;
  @media print {
    margin-top: 0px;
  }
}

.ngee {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 10px;
  a {
    color: #ffffff;
  }
}
.SocialIcon {
  background-color: #ffffff;
  border-radius: 50%;
  width: 60px;
  text-align: center;
  height: 60px;
  margin: 10px;
  display: block;
  @include mobile-theme-screen {
    width: 47px;
    height: 44px;
  }
  @media print {
    width: 44px;
    height: 42px;
  }

  a {
    color: #ffd13bee;
    font-size: 42px;
    text-align: center;
    @include mobile-theme-screen {
      font-size: 32px;
    }
    @media print {
      font-size: 31px;
    }
  }
}
