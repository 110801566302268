//Src/Component/Xp/xp.scss

@import "../../mixins";

.XpContainer {
  background-color: #d11c3b;
  width: 100%;
  color: #ffffff;
  padding: 20px;

  @media print {
    padding: 10px;
    max-height: 330px;
  }
}

.XpBlocList {
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  justify-content: space-between;
  align-items: stretch;
  padding-top: 10px;
  @include mobile-theme-screen {
    flex-flow: wrap;
  }
}
