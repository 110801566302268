* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  color: #001f3f;
  background-color: #ffffff;
  font-family: "Roboto Slab", serif;
  height: 100vh;
  padding: 0;
  box-sizing: border-box;
  max-width: 1800px;
  @media print {
    font-size: 10px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  color: #d11c3b;
  font-size: 24px;
  @media print {
    font-size: 22px;
  }
}

li {
  display: inline-block;
  list-style-type: none;
}

a {
  color: #000000;
}
