@import "./mixins";
@import "./variables";
.App {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-top: 30px;
  @include mobile-theme-screen {
    padding-top: 35px;
  }
  @media print {
    padding-top: 0px;
  }
}
