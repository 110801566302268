//Src/Component/Interest/interest.scss
@import "../../mixins";

.InterestContainer {
  width: 100%;
  position: relative;
  padding-top: 10px;
  padding-left: 20px;
  @media print {
    padding-top: 2px;
  }
}

.InterestBloc {
  width: 60%;
  position: absolute;

  @include mobile-theme-screen {
    width: 60%;
  }

  @media print {
    width: 50%;
  }
}

.InterestContent {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  @media print {
    font-size: 9px;
  }
}

.SocialContainer {
  width: 40%;
  position: absolute;
  right: 0;
}

.SocialIconCOntainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-right: 18px;
  width: 100%;
  justify-content: flex-end;
  //   align-items: center;
  align-content: center;
}
