//Src/Component/MainData/sectionA.scss
@import "../../mixins";

.SectionAContent {
  h1 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
span {
  display: block;
}

.LogoSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  .Logo {
    width: 150px;
    height: 150px;
    background-image: url("/img/logo.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    @media print {
      width: 100px;
      height: 100px;
    }

    @include responsive-theme-large {
      width: 120px;
      height: 120px;
      @include mobile-theme-screen {
        background-image: url("/img/logo-mobile.jpg");
        border-radius: 50%;
        width: 150px;
        height: 150px;
      }

      @include responsive-theme-small {
        background-image: url("/img/logo-mobile.jpg");
        border-radius: 50%;
        width: 150px;
        height: 150px;
      }
    }
    // img {
    //   width: 100%;
    // }
  }
  .LogoTextContent {
    font-weight: 600;
    font-size: 18px;
    padding-left: 10px;
    @media print {
      font-size: 14px;
      padding-left: 0px;
      padding-bottom: 10px;
    }
  }
}

.Name {
  font-size: 34px;
  font-weight: 900;
  @media print {
    font-size: 23px;
  }
}

.Job {
  font-size: 26px;
  font-weight: 500;
  @include responsive-theme-large {
    font-size: 22px;
  }
  @media print {
    font-size: 16px;
  }
}
