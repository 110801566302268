//src/Component/MainData/language.scss
@import "../../mixins";

.Language {
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 30px;
  }

  span {
    padding-left: 5px;
  }
}

.School {
  font-size: 12px;

  @include responsive-theme-small {
    font-size: 11px;
  }

  @media print {
    font-size: 8px;
  }
}
