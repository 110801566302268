//Src/Component/MainData/mainData.scss
@import "../../mixins";

.MainDataContainer {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-left: 20px;
  @include mobile-theme-screen {
    padding-bottom: 15px;
  }
}

.SectionContent {
  width: 60%;
  @include mobile-theme-screen {
    width: 100%;
  }

  @include responsive-theme-small {
    width: 100%;
  }
}

.SectionAandB {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @include mobile-theme-screen {
    flex-wrap: wrap;
  }
}

.SectionA {
  width: 50%;
  @include mobile-theme-screen {
    width: 100%;
  }
  @media print {
    width: 46%;
  }
}
.SectionB {
  width: 50%;
  @include mobile-theme-screen {
    width: 100%;
  }
  @media print {
    width: 54%;
    padding-right: 5px;
  }
}

.SectionC {
  z-index: -1;

  overflow: hidden;
  max-height: 700px;
  @include mobile-theme-screen {
    display: none;
  }

  @include responsive-theme-small {
    display: none;
  }

  @media print {
    max-height: 640px;
  }

  img {
    width: auto;
    display: block;
    z-index: -1;
    min-height: 700px;
    @media print {
      min-height: 613px;
    }
  }
}
